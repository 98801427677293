import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdMenuOpen, MdOutlineMenu } from 'react-icons/md';
import { CiLight } from 'react-icons/ci';
import { FaWallet, FaBell, FaMoon } from 'react-icons/fa';
import { CgSearch } from "react-icons/cg";
import { useMyContext } from '../../context/MyContext';
import { toast } from 'react-hot-toast'
import { useAuth } from '../../routes/Auth';
import { FaCheck, FaTimes, FaExclamationTriangle } from 'react-icons/fa'; 
import { Base_Url, userdata, Url_For_View_Document ,payment} from '../../config/config';
const NavBar = () => {
  const { setIsToggleSidebar, isToggleSidebar, isDarkMode, toggleTheme, setorderId, setIsDarkMode } = useMyContext();
  const navigate = useNavigate();
  const notificationMenuRef = useRef(null);
  const [walletMenuVisible, setWalletMenuVisible] = useState(false);
  const walletMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const [userData, setUserData] = useState([]);
  const [userName, setUserName] = useState("");
  const [userMenu, setUserMenu] = useState(false);
  const { logout } = useAuth()
  const user = JSON.parse(localStorage.getItem('user'));
  const [orderIdInput, setOrderIdInput] = useState('');
  const token = localStorage.getItem('token');        
  const cus_id = localStorage.getItem('cus_id');
  const role = localStorage.getItem('role');
  const [profile, setProfile] = useState()
  const [notificationMenuVisible, setNotificationMenuVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentBalanace, setCurrentBalanace] = useState(0);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.userData.name) {
      setUserName(storedUser.userData.name);
    }
    getPayment();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [cus_id, token]);

  const fetchUserData = async () => {
    const url = Base_Url + userdata;
    if (cus_id) {
      try {
        const response = await fetch(url + "/" + cus_id, {
          method: 'GET',
          headers: {
            'token': token,
            'cus_id': cus_id,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        setUserData(result.data);
        const profile = result.data.profile_image
          ? Url_For_View_Document + result.data.profile_image
          : null;
        setProfile(profile);
        if (result.data.name) {
          setUserName(result.data.name);
        }
        updateNotifications(result.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };


  const toggleWalletMenu = () => {
    const walletStatus = user.userData.wallet_status;

    if (walletStatus === "1") {
      setWalletMenuVisible(!walletMenuVisible);
    }
  };

  const toggleNotificationMenu = () => {
    setNotificationMenuVisible(!notificationMenuVisible);
  };

  const toggleUserMenu = () => {
    setUserMenu(!userMenu);
  }
  const goToTopUp = () => {
    navigate('/payments/walletbalance');
  };

  const navigation = () => {
    navigate('/profile');
  }

  const handleLogout = () => {
    localStorage.removeItem("user");
    document.documentElement.classList.remove('dark');
    setIsDarkMode(false);
    navigate('/login')
    logout()
    localStorage.removeItem('cus_id')
    localStorage.removeItem('token')
    toast.success("LogOut Sucessfully!")
  }

  // const goToHistory = () => {
  //   navigate('/payments/history');
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (walletMenuRef.current && !walletMenuRef.current.contains(event.target)) {
        setWalletMenuVisible(false);
      }
      else if (notificationMenuRef.current && !notificationMenuRef.current.contains(event.target)) {
        setNotificationMenuVisible(false);
      }
      else if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setUserMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [walletMenuRef, userMenuRef]);

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (orderIdInput.trim()) {
        setorderId(orderIdInput);
        navigate('/shipments/tracking');
      } else {
      }
    }
  };


  const updateNotifications = (result) => {
    if(role==='admin') return; 
    const newNotifications = [];
    if (result.profile_flag === "0"
      && result.aadhar_status === "2"
      && result.pan_card_status === "2"
      && result.gst_status === "2") {
      newNotifications.push({
        id: 1,
        message: " All Documents rejected",
        type: "error"
      });
    }
    if (result.profile_flag === "1"
      && result.aadhar_status === "1"
      && result.pan_card_status === "1"
      && result.gst_status === "1") {
      newNotifications.push({
        id: 2,
        message: "All Documnets approved & kyc is done",
        type: "success"
      });
    }
    if (result.aadhar_status === "0" &&
      result.pan_card_status === "0" &&
      result.gst_status === "0" &&
      !result.user_gst_image && !result.user_address_image && !result.user_address_image
      && result.profile_flag === "0") {
      newNotifications.push({
        id: 3,
        message: "Upload your Documnets for Kyc",
        type: "warning"
      });
    }
    if (result.aadhar_status === "1") {
      newNotifications.push({
        id: 4,
        message: "Aadhar document approved",
        type: "success"
      });
    } else if (result.aadhar_status === "2") {
      newNotifications.push({
        id: 5,
        message: "Aadhar document rejected",
        type: "error"
      });
    }
    if (result.pan_card_status === "1") {
      newNotifications.push({
        id: 6,
        message: "PAN document approved",
        type: "success"
      });
    } else if (result.pan_card_status === "2") {
      newNotifications.push({
        id: 7,
        message: "PAN document rejected",
        type: "error"
      });
    }

    if (result.gst_status === "1") {
      newNotifications.push({
        id: 8,
        message: "GST document approved",
        type: "success"
      });
    } else if (result.gst_status === "2") {
      newNotifications.push({
        id  : 9,
        message: "GST document rejected",
        type: "error"
      });
    }

    setNotifications(newNotifications);
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'error':
        return <FaTimes className="w-4 h-4" />;
      case 'warning':
        return <FaExclamationTriangle className="w-4 h-4" />;
      case 'success':
        return <FaCheck className="w-4 h-4" />;
      default:
        return null;
    }
  };


  const getPayment = async () => {
    const url = Base_Url + payment
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.status === "success") {
        setCurrentBalanace(data.totalamount);
      } else {
        toast.error(`Error: ${data.message} (Code: ${data.code})`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };


  const getNotificationStyles = (type) => {
    switch (type) {
      case 'error':
        return {
          icon: 'text-red-500 bg-red-100 dark:bg-red-900/30',
          text: 'text-red-700 dark:text-red-300',
          bg: 'bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500'
        };
      case 'warning':
        return {
          icon: 'text-orange-500 bg-orange-100 dark:bg-orange-900/30',
          text: 'text-orange-500 dark:text-orange-300',
          bg: 'bg-orange-50 dark:bg-orange-900/20 border-l-4 border-orange-500'
        };
      case 'success':
        return {
          icon: 'text-green-500 bg-green-100 dark:bg-green-900/30',
          text: 'text-green-700 dark:text-green-300',
          bg: 'bg-green-50 dark:bg-green-900/20 border-l-4 border-green-500'
        };
      default:
        return {
          icon: 'text-blue-500 bg-blue-100 dark:bg-blue-900/30',
          text: 'text-blue-700 dark:text-blue-300',
          bg: 'bg-blue-50 dark:bg-blue-900/20 border-l-4 border-blue-500'
        };
    }
  };

  // Replace your notification button and menu with this:
  const renderNotificationButton = () => (
    <button
      className="relative text-blue-500 dark:text-blue-400 pr-4 group"
      onClick={toggleNotificationMenu}
    >
      <FaBell
        size={24}
        className={`transform transition-transform duration-200 group-hover:scale-110 ${isDarkMode ? "text-white" : ""}`}
      />
      {notifications.length > 0 && (
        <span className="absolute -top-1 -right-1 flex h-5 w-5">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-5 w-5 bg-red-500 text-white text-xs items-center justify-center">
            {notifications.length}
          </span>
        </span>
      )}

      {notificationMenuVisible && (
        <div
          ref={notificationMenuRef}
          className="absolute right-0 mt-6 w-80 transform transition-all duration-300 origin-top-right"
        >
          <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg dark:dark:bg-[#071a74f6] ring-1 ring-black ring-opacity-5">
            <div className="p-3 border-b dark:border-gray-700">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Notifications
              </h3>
            </div>

            <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
              {notifications.length > 0 ? (
                <div className="divide-y divide-gray-100 dark:dark:bg-[#071a74f6]">
                  {notifications.map((notification, index) => {
                    const styles = getNotificationStyles(notification.type);
                    return (
                      <div
                        key={notification.id}
                        style={{
                          animationDelay: `${index * 150}ms`,
                          animation: 'slideIn 0.3s ease-out forwards'
                        }}
                        className={`p-4 flex items-start space-x-3 ${styles.bg} hover:bg-opacity-80 transition-colors duration-200`}
                      >
                        <div className={`p-2 rounded-full ${styles.icon}`}>
                          {getNotificationIcon(notification.type)}
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className={`text-sm ${styles.text} break-words`}>
                            {notification.message}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className="p-8 text-center">
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 dark:bg-gray-800 mb-4">
                    <FaBell className="w-8 h-8 text-gray-400" />
                  </div>
                  <p className="text-gray-500 dark:text-gray-400">No new notifications</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </button>
  );

  const styleBlock = (
    <style jsx>{`
      @keyframes slideIn {
        from {
          opacity: 0;
          transform: translateY(-10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `}</style>
  );


  return (
    <div className="flex flex-wrap items-center justify-between px-4 py-2  md:py-0 relative  bg-transparent ">
      <div className="flex items-center">

        <Link to="/dashboard">
          <div className="flex items-center cursor-pointer pl-0 md:pl-0">
            <img src="/assets/images/logo1.png" alt="Logo" className="w-[60px] h-[40px] sm:w-[120px] sm:h-[50px] lg:w-[190px] lg:h-[100px]" />
          </div>
        </Link>
        <button
          onClick={() => setIsToggleSidebar(!isToggleSidebar)}
          className="ml-7 md:ml-10 text-blue-500 dark:text-blue-400"
        >
          {isToggleSidebar === false ? <MdMenuOpen size={28} /> : <MdOutlineMenu size={28} />}
        </button>
      </div>

      <div className="flex items-center space-x-2 md:space-x-4  lg:pr-24  pr-0 order-2 md:order-3 relative">
        <button onClick={toggleTheme} className="text-blue-500 dark:text-blue-400 pr-4">
          {isDarkMode ? <CiLight size={24} className="text-white" /> : <FaMoon size={24} />}

        </button>
        <button
          onClick={toggleWalletMenu}
          className="text-blue-500 dark:text-blue-400 pr-4 relative focus:outline-none rounded-full"
          aria-label="Toggle wallet menu"
        >
          <FaWallet size={24} className={`transition-colors duration-200 ${isDarkMode ? "text-white" : ""}`} />
          {walletMenuVisible && (
            <div
              ref={walletMenuRef}
              className="absolute right-0 mt-4 w-56 bg-white dark:bg-[#071a74f6] border border-gray-200 rounded-lg shadow-lg z-50 transition-all duration-200 ease-in-out overflow-hidden"
            >
              <ul className="text-gray-700 dark:text-gray-200 dark:shadow-custom-dark divide-y divide-gray-200 dark:divide-gray-700">
                {/* <li
                  className="py-2 px-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 transition-colors duration-200 flex items-center justify-between"
                  onClick={goToHistory}
                >
                  <span>Payments History</span>
                  <span className="text-gray-400">|</span>
                </li> */}
                <li
                  className="py-2 px-4 cursor-default flex items-center justify-between"
                >
                  <span>Balance</span>
                  <span className="font-semibold">₹ {currentBalanace} </span>
                </li>
                <li
                  className="py-2 px-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 transition-colors duration-200 flex items-center justify-between"
                  onClick={goToTopUp}
                >
                  <span>Top-Up</span>
                  <span className="text-gray-400">|</span>
                </li>
              </ul>
            </div>
          )}
        </button>
        {renderNotificationButton()}
        {styleBlock}
        <button
          onClick={toggleUserMenu}
          className={`text-white hidden lg:flex rounded-md dark:text-white pr-4 items-center relative`}
        >
          <div className="flex items-center space-x-3 md:space-x-5">
            {/* <div className="relative group">
              <img
                src={profile}
                alt="Profile picture"
                className={`w-8 h-10 md:w-8 md:h-8 rounded-full border-2 shadow-lg transform transition-transform duration-300 group-hover:scale-105 z-10 ${isDarkMode ? "border-white" : "border-gray-900"
                  } `}
              />
              <span className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full md:w-3.5 md:h-3.5"></span>
            </div> */}
            <div className="relative group">
              {profile ? (
                <img
                  src={profile}
                  alt="Profile picture"
                  className={`w-8 h-10 md:w-8 md:h-8 rounded-full border-2 shadow-lg transform transition-transform duration-300 group-hover:scale-105 z-10 ${isDarkMode ? "border-white" : "border-gray-900"
                    }`}
                />
              ) : (
                <div
                  className={`w-8 h-10 md:w-8 md:h-8 rounded-full border-2 shadow-lg flex items-center justify-center text-white font-bold text-sm transform transition-transform duration-300 group-hover:scale-105 z-10 ${isDarkMode ? "bg-gray-700 border-white" : "bg-gray-400 border-gray-900"
                    }`}
                >
                  {userName ? userName.slice(0, 2).toUpperCase() : "NA"}
                </div>
              )}
              <span className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full md:w-2.5 md:h-2.5"></span>
            </div>
            <span
              className={`text-lg md:text-md font-semibold transition-colors duration-300 ${isDarkMode ? "text-white glow" : "text-gray-900"
                }`}
            >
              {userName}
            </span>
          </div>

          {userMenu && (
            <div
              ref={userMenuRef}
              className="absolute top-full mt-4 w-28 bg-white dark:bg-[#071a74f6] border border-gray-200 rounded-lg shadow-lg z-50 transition-all duration-200 ease-in-out overflow-hidden"
            >
              <ul className="text-gray-700 dark:text-gray-200 dark:shadow-custom-dark divide-y divide-gray-200 dark:divide-gray-700">
                <li
                  onClick={navigation}
                  className="py-2 px-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 transition-colors duration-200 flex items-center justify-between"
                >
                  Profile
                </li>
                <li
                  onClick={handleLogout}
                  className="py-2 px-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 transition-colors duration-200 flex items-center justify-between"
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </button>

      </div>
      <div className="flex-grow mx-2 md:mx-14 lg:pl-20 order-3 md:order-2 w-full md:w-auto mt-4 md:mt-0">
        <div className="relative flex items-center bg-gray-100 dark:bg-[#071a74f6] rounded-md w-full md:w-[90%]">
          <CgSearch className={`absolute left-3 ${isDarkMode ? "dark:text-white" : "text-custom-gray"}`} size={24} />
          <input
            type="text"
            value={orderIdInput}
            onChange={(e) => setOrderIdInput(e.target.value)}
            onKeyDown={handleSearchKeyDown}
            placeholder="Search with LRN/AWB/OID"
            className={`font-sans bg-gray-200 dark:bg-[#071a74f6] w-full h-[40px] border-0 outline-none rounded-[10px] pl-[50px] pr-[10px] bg-opacity-70 text-[12px] md:text-[22px] ${isDarkMode ? "placeholder-white" : "placeholder-custom-gray"} font-san dark:text-white`}
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
