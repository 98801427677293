import React, { useEffect, useState } from 'react';
import { Search, MapPin } from 'lucide-react';
import { useMyContext } from '../../../context/MyContext';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import { Base_Url, getpincode } from '../../../config/config';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const PincodeService = () => {
  const [pincode, setPincode] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const [pincodeData, setPincodeData] = useState([]);

  const handleCheck = async () => {
    setIsChecking(true);
    const token = localStorage.getItem('token');
    const cus_id = localStorage.getItem('cus_id');
    const urlgetPincode = `${Base_Url}${getpincode}?pincode=${pincode}`;
    try {
      const response = await fetch(urlgetPincode, {
        method: 'GET',
        headers: {
          'token': token,
          'cus_id': cus_id,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data && data.status === "SUCCESS") {
        // const { city, state,pincode } = data.data[0]; 
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Servicable Pincode',
        //   html: `<p> <strong>${pincode}</strong> is a servicable pincode.</p>
        //          <p><strong>City:</strong> ${city}</p>
        //          <p><strong>State:</strong> ${state}</p>`,
        // });
        setPincodeData(data.data);
        console.log("daatata", pincodeData);
        setIsChecking(false);
      }
      else if (data && data.message === "Unauthorized access - Invalid token") {
        toast.error(data.message + " Please login Again");
        setIsChecking(false);
      }

      else if (data && data.status === "FAIL") {
        toast.error("Something Went Wrong Please Try Again With Another Pincode");
        setIsChecking(false);
      }
      else {
        toast.error("Failed to fetch city and state data");
        setIsChecking(false);
      }
    } catch (error) {
      console.error('Error fetching pincode data:', error);
      toast.error("Error fetching pincode data");
      setIsChecking(false);
    }
  };


  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768
                ? { top: '113px', height: 'calc(100vh - 115px)', overflowY: 'auto' }
                : {}
            }
          >
            <Sidebar />
          </div>
        )}
        <div className='shadow-custom-light dark:shadow-custom-dark rounded-custom w-full mt-3 mb-3 mr-3 ml-4'>
          <div className={`p-4 md:p-6 min-h-[50vh] custom-scrollbar mt-10 overflow-y-auto dark:bg-[rgba(1,14,78,0.96)] ${isToggleSidebar ? 'ml-0' : 'mr-0'} ${isToggleSidebar ? 'w-full' : 'w-full'} mb-8 md:mb-0 `}>
            <div className="max-w-4xl mx-auto">
              <div className="bg-white dark:bg-[rgba(1,14,78,0.96)]   dark:shadow-custom-dark shadow-custom-light rounded-custom overflow-hidden">
                <div className=" bg-customPurple p-6 text-white rounded-custom shadow-custom-light dark:shadow-custom-dark">
                  <h2 className="text-2xl font-bold">Pincode Service</h2>
                  <p className="text-purple-200">Check delivery availability for your area</p>
                </div>

                <div className="p-8">
                  <div className="mb-8">
                    <label htmlFor="pincode" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                      Enter Pincode
                    </label>
                    <div className="relative rounded-md shadow-lg">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MapPin className="h-6 w-6 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        type="text"
                        name="pincode"
                        id="pincode"
                        className={`block w-full pl-10 pr-12 py-3 shadow-custom-light dark:shadow-custom-dark rounded-custom border-gray-300  text-lg dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:placeholder-white  placeholder-custom-gray`}
                        placeholder="e.g., 123456"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center">
                        <button
                          type="button"
                          onClick={handleCheck}
                          disabled={isChecking || pincode.length !== 6}
                          className={`inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white transition-all duration-300 ease-in-out ${isChecking || pincode.length !== 6
                            ? 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed'
                            : 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                            }`}
                        >
                          {isChecking ? (
                            <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                            </svg>
                          ) : (
                            <Search className="h-5 w-5 mr-2" />
                          )}
                          {isChecking ? 'Checking...' : 'Check'}
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Enter a 6-digit pincode to check service availability in your area.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {pincodeData && pincodeData.length > 0 && (
            <div
              className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white" : "bg-white"
                }`}
            >
              <div className="overflow-x-auto shadow-md rounded-lg">
                <table className="ml-28 w-[900px] divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-[#0540a6dd] text-white text-[14px]">
                    <tr>
                      <th className="p-4 text-left font-medium tracking-wide uppercase">
                        Pincode
                      </th>
                      <th className="p-4 text-left font-medium tracking-wide uppercase">
                        City
                      </th>
                      <th className="p-4 text-left font-medium tracking-wide uppercase">
                        State
                      </th>
                      <th className="p-4 text-left font-medium tracking-wide uppercase">
                        Partner(Service Available)
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-[rgba(1,14,78,0.96)] divide-y divide-gray-200 dark:divide-gray-700">
                    {pincodeData.map((shipment, index) => {
                      // Extract partner names with value "1"
                      const partners = [];
                      if (shipment.delhivery === "1") partners.push("Delhivery");
                      if (shipment.shiprocket === "1") partners.push("Shiprocket");
                      if (shipment.ecom === "1") partners.push("Ecom");
                      if (shipment.ekart === "1") partners.push("Ekart");

                      return (
                        <tr
                          key={index}
                          className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                        >
                          <td className="p-4 text-sm text-gray-800 dark:text-gray-300">
                            {shipment.pincode}
                          </td>
                          <td className="p-4 text-sm text-gray-800 dark:text-gray-300">
                            {shipment.city}
                          </td>
                          <td className="p-4 text-sm text-gray-800 dark:text-gray-300">
                            {shipment.state}
                          </td>
                          <td className="p-4 text-sm text-gray-800 dark:text-gray-300">
                            {partners.length > 0 ? partners.join(" ") : "No Partner"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default PincodeService;
