import React, { useState, useEffect } from 'react';
import { Camera, Save, Upload } from 'lucide-react';
import Sidebar from '../../component/sidebar/sidebar';
import { useMyContext } from '../../context/MyContext';
import NavBar from '../../component/navBar/navBar';
import '../../css/custom-scrollbar.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../../component/spinner/spinner';
import { Base_Url, userdata, updateprofile, Url_For_View_Document } from '../../config/config';
import { useNavigate } from 'react-router-dom';

const UserProfileEdit = () => {
    const { isDarkMode, isToggleSidebar } = useMyContext();
    const [cancelCheckFile, setCancelCheckFile] = useState(null);
    const token = localStorage.getItem('token');
    const cusid = localStorage.getItem('cus_id');
    const [isLoading, isSetLoading] = useState(false);
    const navigate = useNavigate();

    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [selectedProfileImage, setSelectedProfileImage] = useState(null);

    const [userData, setUserData] = useState({
        id: cusid,
        company_name: "test",
        name: '',
        bio: '',
        email: '',
        phone: '',
        website: '',
        address: '',
        account_holder: '',
        bank_name: '',
        account_no: '',
        ifsc_code: ''
    });

    const handleCancelCheckUpload = (event) => {
        const file = event.target.files[0];
        setCancelCheckFile(file);
    };

    const handleProfileImage = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        setSelectedProfileImage(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setProfileImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleCancelCheckRemove = () => {
        setCancelCheckFile(null);
    };
  

    const generateInitialsAvatar = (name) => {
        if (!name) return null;
        const initials = name.charAt(0).toUpperCase() + name.charAt(1).toUpperCase();
        return (
            <div
                className="rounded-full mt-16 flex items-center justify-center bg-customPurple text-white text-6xl font-bold"
                style={{ width: '193px', height: '193px' }}
            >
                {initials}
            </div>

        );
    }; 

    useEffect(() => {
        fetchUserData();
    }, [cusid, token]);

    const fetchUserData = async () => {
        const url = Base_Url + userdata;
        if (cusid) {
            try {
                const response = await fetch(url + "/" + cusid, {
                    method: 'GET',
                    headers: {
                        'token': token,
                        'cus_id': cusid,
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();

                if (result.status === "SUCCESS") {
                    const { data } = result;

                    // Set user data
                    setUserData({
                        id: cusid,
                        name: data.name || '',
                        company_name: data.company_name || '',
                        bio: data.bio,
                        email: data.email || '',
                        phone: data.phone || '',
                        website: data.website || '',
                        address: data.address || '',

                        // Bank Details
                        account_holder: data.account_holder_name,
                        bank_name: data.bank_name,
                        account_no: data.account_no,
                        ifsc_code: data.ifsc_code
                    });
                    if (data.profile_image) {
                        const profileWithUrl = Url_For_View_Document + data.profile_image
                        setProfileImagePreview(profileWithUrl);
                    }
                    else {
                        setProfileImagePreview(data.profile_image);
                    }
                } else {
                    toast.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                toast.error('Something went wrong. Please try again.');
            }
        }
    };

    const handleSubmit = async () => {
        const formData = new FormData();

        // Append text fields
        for (const key in userData) {
            formData.append(key, userData[key]);
        }

        // Append profile image if selected
        if (selectedProfileImage) {
            formData.append('profile_image', selectedProfileImage);
        }

        if (cancelCheckFile) {
            formData.append('cancel_check', cancelCheckFile);
        }

        try {
            isSetLoading(true);
            const response = await axios.post(`${Base_Url}${updateprofile}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': token,
                    'cus_id': cusid,
                },
            });
            if (response.data.status === "SUCCESS") {
                toast.success(response.data.message);
                isSetLoading(false);
                setTimeout (()=>{
                    navigate("/dashboard");
                },1000)
            } else {
                toast.error(response.data.message.ifsc_code);
                toast.error(response.data.message.profile_image);
                toast.error(response.data.message.ifsc_code);
                toast.error(response.data.message.account_no);
                toast.error(response.data.message.address);
                toast.error(response.data.message.website);
                toast.error(response.data.message.cancel_check);
                isSetLoading(false);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Something went wrong. Please try again.');
            isSetLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === 'phone') {
            const phoneRegex = /^[0-9]{10}$/; 
            if (!phoneRegex.test(value)) {
                toast.error('Phone number must be a 10-digit number');
                return; 
            }
        }
        setUserData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };
    return (
        <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-gray-100'}`}>
            <div className="z-10">
                <NavBar />
            </div>

            <div className="flex flex-1 overflow-hidden">
                {isToggleSidebar && (
                    <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] md:bg-transparent mt-4 md:w-64 md:z-20`}
                        style={
                            window.innerWidth < 768
                                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                                : {}
                        }>
                        <Sidebar />
                    </div>
                )}
                <div className='rounded-custom ml-4 mb-2 shadow-custom-light dark:shadow-custom-dark overflow-y-auto w-full custom-scrollbar  mt-4 mr-3'>
                    <div className="flex-1 overflow-y-auto p-4 md:p-6 lg:p-8 mt-3">
                        <div className="max-w-4xl mx-auto bg-white dark:bg-[rgba(1,14,78,0.96)] rounded-custom custom-scrollbar shadow-custom-light dark:shadow-custom-dark overflow-hidden">
                            <div className="px-6 py-4 bg-gray-50 dark:bg-[rgba(1,14,78,0.96)] border-b">
                                <h1 className="text-2xl font-bold  dark:bg-[rgba(1,14,78,0.96)]">Edit Profile</h1>
                            </div>
                            <div className="p-6">
                                <div className="mb-8">
                                    <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200">Public Information</h2>
                                    <div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-6">
                                        <div className="relative w-[193px]">
                                            {profileImagePreview ? (
                                                <img
                                                    src={profileImagePreview}
                                                    alt="Profile"
                                                    className="w-56 mt-16 h-48 object-cover border-4 border-white dark:border-gray-700 shadow-custom-light dark:shadow-custom-dark rounded-full"
                                                />

                                            ) : (
                                                generateInitialsAvatar(userData.name)
                                            )}
                                            <button
                                                onClick={() => document.getElementById("profileImageInput").click()}
                                                className="absolute bottom-0 right-0 bg-customPurple text-white p-2 rounded-full shadow-lg hover:bg-purple-700 transition duration-300"
                                            >
                                                <Camera className="w-5 h-5" />
                                            </button>
                                            <input
                                                type="file"
                                                id="profileImageInput"
                                                accept=".pdf,.jpg,.jpeg,.png"
                                                style={{ display: 'none' }}
                                                // onChange={(e) => {
                                                //     const file = e.target.files[0];
                                                //     if (file) {
                                                //         setProfileImage(URL.createObjectURL(file));
                                                //     }
                                                // }}
                                                onChange={handleProfileImage}
                                            />
                                        </div>

                                        <div className="flex-grow space-y-4 w-full  md:w-auto">
                                            <div>
                                                <label htmlFor="name" className="block text-gray-700 dark:text-gray-200 mb-2">Full Name</label>
                                                <input
                                                    id="name"
                                                    type="text"
                                                    placeholder="Full name"
                                                    value={userData.name}
                                                    onChange={handleInputChange}
                                                    className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]"
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="username" className="block text-gray-700 dark:text-gray-200 mb-2">Username</label>
                                                <input
                                                    id="username"
                                                    type="text"
                                                    placeholder="Username"
                                                    value={userData.company_name}
                                                    onChange={handleInputChange}
                                                    className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]"
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="biography" className="block text-gray-700 dark:text-gray-200 mb-2">Biography</label>
                                                <textarea
                                                    id="bio"
                                                    placeholder="Biography"
                                                    value={userData.bio}
                                                    onChange={handleInputChange}
                                                    className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]"
                                                    rows="4"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-white">Private Information</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="email" className="block  text-gray-700 dark:text-gray-200 mb-2">Email</label>
                                            <input
                                                id="email"
                                                type="email"
                                                placeholder="Email"
                                                disabled
                                                value={userData.email}
                                                onChange={handleInputChange}
                                                className="w-full p-3  border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="phone" className="block text-gray-700 dark:text-gray-200 mb-2">Phone</label>
                                            <input
                                                id="phone"
                                                type="tel"
                                                placeholder="Phone"
                                                value={userData.phone}
                                                onChange={handleInputChange}
                                                className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="website" className="block text-gray-700 dark:text-gray-200 mb-2">Website</label>
                                            <input
                                                id="website"
                                                type="url"
                                                placeholder="Website"
                                                value={userData.website}
                                                onChange={handleInputChange}
                                                className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="UserId" className="block text-gray-700 dark:text-gray-200 mb-2">UserId</label>
                                            <input
                                                id="UserId"
                                                type="UserId"
                                                placeholder="UserId"
                                                disabled
                                                value={userData.id}
                                                onChange={handleInputChange}
                                                className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="address" className="block text-gray-700 dark:text-gray-200 mb-2">Address</label>
                                        <textarea
                                            id="address"
                                            placeholder="Address"
                                            value={userData.address}
                                            onChange={handleInputChange}
                                            className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]"
                                            rows="3"
                                        ></textarea>
                                    </div>
                                </div>

                                {/* Bank Details Section */}
                                <div className="mt-8">
                                    <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-white">Bank Details</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="account-holder-name" className="block text-gray-700 dark:text-white mb-2">Account Holder Name</label>
                                            <input
                                                id="account_holder"
                                                type="text"
                                                placeholder="Enter Account Holder Name"
                                                value={userData.account_holder}
                                                onChange={handleInputChange}
                                                className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark dark:bg-[rgba(1,14,78,0.96)]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="bank-name" className="block text-gray-700 dark:text-white mb-2">Bank Name</label>
                                            <input
                                                id="bank_name"
                                                type="text"
                                                placeholder="Enter Bank Name"
                                                value={userData.bank_name}
                                                onChange={handleInputChange}
                                                className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark dark:bg-[rgba(1,14,78,0.96)]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="account-number" className="block text-gray-700 dark:text-white mb-2">Account Number</label>
                                            <input
                                                id="account_no"
                                                type="text"
                                                placeholder="Enter Account Number"
                                                value={userData.account_no}
                                                onChange={handleInputChange}
                                                className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark dark:bg-[rgba(1,14,78,0.96)]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="ifsc-code" className="block text-gray-700 dark:text-white mb-2">IFSC Code</label>
                                            <input
                                                id="ifsc_code"
                                                type="text"
                                                placeholder="Enter IFSC Code"
                                                value={userData.ifsc_code}
                                                onChange={handleInputChange}
                                                className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark dark:bg-[rgba(1,14,78,0.96)]"
                                            />
                                        </div>
                                    </div>

                                    {/* Cancel Check Upload Section */}
                                    <div className="mt-6">
                                        <label className="block text-gray-700 dark:text-white mb-2">Upload Cancelled Check</label>
                                        <div className="flex items-center space-x-4">
                                            <input
                                                type="file"
                                                id="cancel-check-upload"
                                                className="hidden"
                                                accept=".pdf,.jpg,.jpeg,.png"
                                                onChange={handleCancelCheckUpload}
                                            />
                                            <label
                                                htmlFor="cancel-check-upload"
                                                className="flex items-center space-x-2 bg-customPurple text-white px-4 py-2 rounded-custom cursor-pointer hover:bg-purple-700 transition duration-300"
                                            >
                                                <Upload className="w-5 h-5" />
                                                <span>Upload Cancelled Check</span>
                                            </label>

                                            {cancelCheckFile && (
                                                <div className="flex items-center space-x-2">
                                                    <span className="text-gray-700 dark:text-white">
                                                        {cancelCheckFile.name}
                                                    </span>
                                                    <button
                                                        onClick={handleCancelCheckRemove}
                                                        className="text-red-500 hover:text-red-700"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-6 py-4 bg-gray-50 flex justify-end bg-white dark:bg-[rgba(1,14,78,0.96)]">
                                <button className="flex items-center space-x-2 bg-customPurple text-white px-6 py-2 rounded-custom  transition duration-300"
                                    onClick={handleSubmit}>
                                    <Save className="w-5 h-5" />
                                    {isLoading ? <Spinner /> : <span>Save Changes</span>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
        </div>
    );
};

export default UserProfileEdit;   